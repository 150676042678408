module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"fileName":false,"displayName":false,"pure":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CQQ55S17NB"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1183014738920922"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    }]
