export const STORAGE_LANG_KEY = 'preferred_lang';

export const STORAGE_THEME_KEY = 'theme';

export const STORAGE_LIGHT_THEME_VALUE = 'light';

export const STORAGE_DARK_THEME_VALUE = 'light'; // Todo: replace with 'dark'

export const LIGHT_THEME_CLASSNAME = 'lightTheme';

export const DARK_THEME_CLASSNAME = 'lightTheme'; // Todo: replace with 'darkTheme'

export const IMAGE_FORMATS = {
  SVG: 'svg',
  PNG: 'png',
  JPG: 'jpg',
  GIF: 'gif',
};

export const PAYMENT_CONFIG = {
  id: 'widget-wfp-script',
  type: 'text/javascript',
  src: 'https://secure.wayforpay.com/server/pay-widget.js',
  secretKey: 'b27a26571cec1b0fd7217be1a66ed66bfed058aa',
  merchantAccount: 'www_u_aid_org',
  merchantDomainName: 'www.u-aid.org',
  authorizationType: 'SimpleSignature',
  productName: 'Благодійний внесок',
  productNameEn: 'Charitable donation',
  productCount: 1,
};

export const REGULAR_CONFIG = {
  id: 'widget-wfp-script',
  type: 'text/javascript',
  src: 'https://secure.wayforpay.com/server/pay-widget.js',
  secretKey: 'b27a26571cec1b0fd7217be1a66ed66bfed058aa',
  merchantAccount: 'www_u_aid_org',
  merchantDomainName: 'www.u-aid.org',
  regularMode: 'monthly',
  authorizationType: 'SimpleSignature',
  productName: 'Благодійний внесок',
  productCount: 1,
};

export const CURRENCY_SYMBOL = {
  USD: '$',
  UAH: '₴',
};

export const PAYMENT_SETTINGS = {
  USD: {
    symbol: '$',
    range: {
      10: 'https://secure.wayforpay.com/button/b70dc5a58d4be',
      25: 'https://secure.wayforpay.com/button/b94c07bfd2fa1',
      100: 'https://secure.wayforpay.com/button/b9265b37e9c96',
    }
  },
  UAH: {
    symbol: '₴',
    range: {
      500: 'https://secure.wayforpay.com/button/bcf2856e8fb16',
      1000: 'https://secure.wayforpay.com/button/b25983e63d753',
      5000: 'https://secure.wayforpay.com/button/b0b95af12e202',
    }
  }
};
